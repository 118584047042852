var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogTransferJob,
        width: _vm.isMobileScreen ? "90%" : "50%",
        title: "Transfer Job",
      },
      on: { open: _vm.handleTransferJobsDialogOpen },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                { attrs: { label: "Selected Barge" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.selectedBargeName,
                      callback: function ($$v) {
                        _vm.selectedBargeName = $$v
                      },
                      expression: "selectedBargeName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-top": "18px" },
                  attrs: { label: "Transfer To" },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        filterable: "",
                        loading: _vm.availableBargesLoading,
                      },
                      on: {
                        "visible-change": _vm.visibleChange,
                        change: _vm.handleChange,
                      },
                      model: {
                        value: _vm.bargeShipId,
                        callback: function ($$v) {
                          _vm.bargeShipId = $$v
                        },
                        expression: "bargeShipId",
                      },
                    },
                    _vm._l(_vm.filteredBargeList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          disabled:
                            _vm.jobData.type === 3 &&
                            item.id === _vm.jobData.vesselShipId,
                          label: item.shipName,
                          value: item.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "el-dialog__footer" },
        [
          _c(
            "el-button",
            {
              attrs: { outline: "", round: "" },
              on: { click: _vm.handleTransferJobsDialogClose },
            },
            [_vm._v(" Cancel ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                round: "",
                type: "primary",
                disabled: !_vm.bargeShipId,
                loading: _vm.loading,
              },
              on: { click: _vm.transferBarge },
            },
            [_vm._v(" Submit ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }